import { initializeApp } from "firebase/app";
import { getMessaging, getToken as getFcmToken } from "firebase/messaging";
const {
	REACT_APP_VAPID_KEY,
	REACT_APP_FIREBASE_KEY,
	REACT_APP_FIREAPP_KEY,
	REACT_APP_MSG_ID,
	REACT_APP_MEASURE_ID,
} = process.env;

const firebaseConfig = {
  apiKey: "AIzaSyAK7OAvLjOb0kmdYAEutkKRsDMyB8LBfvo",
  authDomain: "eprisk2024.firebaseapp.com",
  projectId: "eprisk2024",
  storageBucket: "eprisk2024.appspot.com",
  messagingSenderId: "1051396995253",
  appId: "1:1051396995253:web:3781a822294f6a41d3a6fc",
  measurementId: "G-R3C04C5QVJ"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const publicKey = REACT_APP_VAPID_KEY;
export const getToken = async (setTokenFound) => {
	let currentToken = "";
	try {
		const permission = await Notification.requestPermission();
		if (permission === "granted") {
			const token = await getFcmToken(messaging, {
				vapidKey:
					"BKz_WcdZ8fMaFY5foi4x8RRHM4f-63j0GrIkcmdnszm4QG2dZFPrAP3sKtUmmcimkpt5i-AYsufF-h1lRd5jM80",
			});
			if (token) {
				setTokenFound(true);
				currentToken = token;
			} else {
				setTokenFound(false);
			}
		} else {
			setTokenFound(false);
		}
	} catch (error) {
	}
	return currentToken;
};
export const onMessageListener = () =>
	new Promise((resolve) => {
		messaging.onMessage((payload) => {
			resolve(payload);
		});
	});
