import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

function BlockModal({show,close}) {

  return (
    <>
      <Modal show={show} size="sm" dialogClassName="modal-rounded modal-280" centered onHide={close}>
        <Modal.Body className='text-center py-4'>

            <h6 class="fs-17-600 mb-0">Are you sure you want to block this user?</h6>
            <div className="d-flex gap-2 mt-4">
                <Link className='btn btn-outline-primary rounded-pill fs-13 text-primary py-2 w-50'>Cancel</Link>
                <Link className='btn btn-primary rounded-pill fs-13 text-white py-2 w-50'>Block</Link>
            </div>     
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BlockModal;