import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Eye from "../assets/icons/eye.svg";
import EyeClose from "../assets/icons/eye-close.svg";
import PasswordChangeodal from "../components/modals/PasswordChangeodal";
import { useChangePasswordMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import { useNavigate } from "react-router-dom";
import Loader from "../utils/loader";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [oldToggle, setOldToggle] = useState(true);
  const [newToggle, setNewToggle] = useState(true);
  const [confirmToggle, setConfirmToggle] = useState(true);

  const [changePassword, { data, error, isLoading }] =
    useChangePasswordMutation();

  const changePasswordHandler = () => {
    if (!oldPass) {
      PopUp("Please enter old password", "", "error");
      return;
    }

    if (!newPass) {
      PopUp("Please enter new password", "", "error");
      return;
    }

    if (!confirmPass) {
      PopUp("Please enter confirm password", "", "error");
      return;
    }

    if (newPass !== confirmPass) {
      PopUp("New password is not matched with Confirm password", "", "error");
      return;
    }
    changePassword({
      oldPassword: oldPass,
      newPassword: newPass,
      confirmPassword: confirmPass,
    });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Password changed successfully", "", "success");
      localStorage.clear();
      navigate("/");
    }
    handleErrors(error);
  }, [data, error]);
  const showPassword = () => setPassword(true);
  const closePassword = () => setPassword(false);
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-5 mx-auto">
          <div className="changePassword">
            <div class="input-group mb-3 position-relative">
              <span class="absolute-center-icon">
                <img
                  src={oldToggle ? Eye : EyeClose}
                  alt="password"
                  onClick={() => setOldToggle(!oldToggle)}
                />
              </span>
              <input
                type={oldToggle ? "password" : "text"}
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Enter New Password*"
              />
            </div>
            <div class="input-group mb-3 position-relative">
              <span class="absolute-center-icon">
                <img
                  src={newToggle ? Eye : EyeClose}
                  alt="password"
                  onClick={() => setNewToggle(!newToggle)}
                />
              </span>
              <input
                type={newToggle ? "password" : "text"}
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Enter New Password*"
              />
            </div>
            <div class="input-group position-relative">
              <span class="absolute-center-icon">
                <img
                  src={confirmToggle ? Eye : EyeClose}
                  alt="password"
                  onClick={() => setConfirmToggle(!confirmToggle)}
                />
              </span>
              <input
                type={confirmToggle ? "password" : "text"}
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Confirm Password*"
              />
            </div>
            <div class="input-group mt-4">
              <Link
                class="btn btn-primary rounded-pill w-100 justify-content-center"
                onClick={changePasswordHandler}
              >
                {isLoading ? <Loader /> : "Change Password"}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <PasswordChangeodal show={password} close={closePassword} />
    </>
  );
};

export default ChangePassword;
