import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Chart1 from "../assets/images/chart1.jpg";
import Chart2 from "../assets/images/chart2.png";
import Chart3 from "../assets/images/chart3.png";
import Chart4 from "../assets/images/chart4.jpg";
import ChartOne from "../components/ChartOne";
import { useGetReportBarQuery } from "../services/apis";

const SavedReports = () => {
  const { data, refetch } = useGetReportBarQuery();

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <Accordion
            defaultActiveKey="0"
            className="border-0 gap-3 d-flex flex-column"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Top-10 Risks in Terms of Initial Cost Evaluation
              </Accordion.Header>
              <Accordion.Body>
                <ChartOne />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Risks by ownership, Severity and Cost Exposure
              </Accordion.Header>
              <Accordion.Body>
                <img src={Chart2} alt="" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Actions by Ownership and Status
              </Accordion.Header>
              <Accordion.Body>
                <img src={Chart4} alt="" />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default SavedReports;
