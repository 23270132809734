import React, { useState } from "react";
import { Link } from "react-router-dom";
import Edit from "../assets/icons/edit.svg";
import Pencil from "../assets/icons/pencil.svg";
import Block from "../assets/icons/block.svg";
import EditContractModal from "../components/modals/EditContractModal";
import CreateRiskManager from "../components/modals/CreateRiskManager";
import BlockModal from "../components/modals/BlockModal";

const RiskManagers = () => {
  const [risk, setRisk] = useState(false);
  const showRisk = () => setRisk(true);
  const closeRisk = () => setRisk(false);

  const [block, setBlock] = useState(false);
  const showBlock = () => setBlock(true);
  const closeBlock = () => setBlock(false);
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
            <input
              type="search"
              placeholder="Search"
              className="rounded-pill searchBar"
            />
            <Link
              className="btn btn-primary rounded-pill fs-14-normal px-3 h-48 align-items-center d-flex"
              to="/add-contract"
            >
              +Create New
            </Link>
          </div>
          <div className="table-responsive br-10 mt-3">
            <table class="table table-style-1 mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>
                    Total Projects
                  </th>
                  <th>
                    Total Risks
                  </th>
                  <th>
                    Engineers Under Him
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Joseph</td>
                  <td>jos@mail.com</td>
                  <td>23</td>
                  <td>23</td>
                  <td>Joseph</td>
                  <td>
                    <div className="table-action">
                      <Link onClick={showRisk} className="me-2">
                        <img src={Pencil} alt="Edit" />
                      </Link>
                      <Link onClick={showBlock} className="ms-2">
                        <img src={Block} alt="Delete" />
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4 d-flex justify-content-center gap-2 align-items-center">
            <span className="showResult">Showing results 8 of 64</span>
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center mb-0">
                <li class="page-item">
                  <a
                    class="page-link"
                    href="#"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    &#8249; Previous
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next &#8250;
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <CreateRiskManager show={risk} close={closeRisk} />
      <BlockModal show={block} close={closeBlock} />
    </>
  );
};

export default RiskManagers;
